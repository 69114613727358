import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const Title = () => <span>
    CV
  </span>;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpressiveListContainer = makeShortcode("ExpressiveListContainer");
const layoutProps = {
  Title,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`EXPERIENCE`}</h2>
    <ExpressiveListContainer title="2024 - Present" mdxType="ExpressiveListContainer">
  Watson Document Understanding<br />
  Staff Software Engineer<br /><br />
  IBM Watson Orchestrate<br />
  IBM Research<br /><br />
  Collaborated with IBM Research to incorporate state-of-the-art document conversion models into an inner source library for usage in various IBM software products.
    </ExpressiveListContainer>
    <ExpressiveListContainer title="2024 - Present" mdxType="ExpressiveListContainer">
  Document Processing Extension<br />
  Staff Software Engineer<br /><br />
  IBM Watson Orchestrate<br /><br />
  Merged with IBM Watson Orchestrate to expose document processing functionality as a skill used by IBM foundational models, a form of generative AI.
    </ExpressiveListContainer>
    <ExpressiveListContainer title="2023" mdxType="ExpressiveListContainer">
  Supply Chain Intelligence Suite<br />
  Software Engineer<br /><br />
  IBM Sterling<br /><br />
  Prototyped software that automatically detected incident reports from various sources and generated the corresponding tickets for the right immediate responders. Gained experience in working directly with top IBM technical executives.
    </ExpressiveListContainer>
    <ExpressiveListContainer title="2022 - 2024" mdxType="ExpressiveListContainer">
  Document Processing<br />
  Software Engineer<br /><br />
  IBM Cloud Pak for Business Automation<br /><br />
  Architect of the ontology snapshot, the foundation of all document processing components, models, and tools with the ability to share reusable knowledge across various tasks. Most notably contributed to enhancements in alias matching, document classification, and checkbox detection.<br /><br />
  Recognized as a top technical contributor.
    </ExpressiveListContainer>
    <h2>{`AWARDS`}</h2>
    <ExpressiveListContainer title="IBM Tech 2024" mdxType="ExpressiveListContainer">
    Awarded to IBM's premier technical contributors that drove innovation, transformed culture, and accelerated growth in 2023. 
    </ExpressiveListContainer>
    <h2>{`TECHNICAL SKILLS`}</h2>
    <ExpressiveListContainer title="Development Tools" mdxType="ExpressiveListContainer">
    Git, Linux, Virtual Machine, Jenkins, PyTest, Unit Test, JIRA, Slack, VS Code.
    </ExpressiveListContainer>
    <ExpressiveListContainer title="API Development" mdxType="ExpressiveListContainer">
     Python, Flask, Swagger UI, IBM DB2, PostgreSQL, Celery, Docker, Kubernetes, CronJob.
    </ExpressiveListContainer>
    <ExpressiveListContainer title="Document Processing" mdxType="ExpressiveListContainer">
    Python, Pandas, Numpy, Scikit-Learn, Tensorflow, ImageHash, Open-CV, ONNX Runtime, IBM OCR, IBM SystemT, Table Extraction GTE, RetinaNet.
    </ExpressiveListContainer>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      